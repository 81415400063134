<template>
    <div>
        <div class="default_box" v-if="preLoad">
            <div class="default_header" v-if="this_category && this_category.name">
                <h1 v-html="this_category.name"></h1>
            </div>
            <SearchPlagin cat_search="Organizations" info="Поиск в справочнике организаций" />
            <div class="sort_box">
                <div class="sort_box_title">Сортировать</div>
                <div v-if="list && list.length">
                    <button type="button" @click="sortListByName()">По названию</button>
                    <!-- <button v-if="geo.isActive" type="button" @click="getListByGeo()">По расстоянию</button> -->
                </div>
            </div>
            <div class="default_box_content">
                <div v-if="list && list.length">
                    <div v-for="(item, index) in list.slice(0, limit)" :key="index">
                        <article>
                            <OrganisationCard :item="item" />
                        </article>
                        <keep-alive>
                            <AdsPlaginNew v-if="$myCity && !$myCity.celat_active && index !== 0 && index % 3 === 0"
                                :direction="'horizontal'" />
                        </keep-alive>

                    </div>
                </div>
                <button v-if="limit < list.length" class="load_next_button" @click="limit += 10">Показать ещё</button>
            </div>
        </div>
        <div v-else>
            <PreLoadBox />
        </div>
    </div>
</template>
<script>
/* eslint-disable no-console */
import SearchPlagin from '@/components/Plagins/SearchPlagin';
import OrganisationCard from './Components/OrganisationCard';
export default {
    name: "CatalogList",
    components: {
        SearchPlagin,
        OrganisationCard
    },
    metaInfo() {
        return {
            title: this.this_category && this.this_category.name ? `${this.this_category.name} ` : (this.list && this.list.length ? `Справочник организаций ` : null),
            meta: [
                { name: 'keywords', itemprop: 'keywords', content: this.this_category && this.this_category.keywords && this.this_category.keywords.length ? `${this.$toString(this.this_category.keywords)}` : '' },
                { name: 'description', itemprop: 'description', content: this.this_category && this.this_category.name ? `${this.this_category.name} в городе ${this.$myCity.name}. Подробная карта городов России: поиск по адресу, телефоны, отзывы, фото и часы работы фирм.` : 'Подробная карта городов России: поиск по адресу, телефоны, отзывы, фото и часы работы фирм.' },
                { property: 'og:url', content: this.$config.url + this.$route.fullPath },
                { property: 'og:title', content: this.this_category && this.this_category.name ? `${this.this_category.name} ${this.$myCity.name}` : `Справочник организаций ${this.$myCity.name}` },
                { property: 'og:description', content: this.this_category && this.this_category.name ? `${this.this_category.name} в городе ${this.$myCity.name}. Подробная карта городов России: поиск по адресу, телефоны, отзывы, фото и часы работы фирм.` : 'Подробная карта городов России: поиск по адресу, телефоны, отзывы, фото и часы работы фирм.' },
                { property: 'og:image', content: 'https://okaygorod.com/images/logoHD.png' },
                { property: 'og:type', content: 'website' }
            ]
        }
    },
    data() {
        return {
            preLoad: null,
            list: [],
            geo: { isActive: false, x: 0, y: 0 },
            //listByGeo: [],
            this_category: {},
            def_img: 'https://city.ooogoroda.mobi/uploads/images/def_Organizations.jpg',
            limit: 10
        }
    },
    methods: {
        api(x = 0, y = 0) {
            this.$scrollToTop();
            let url = this.$config.api_url + this.$route.params.city + '/Organizations.getList';
            this.$http.get(url, {
                params: {
                    id: this.$route.params.id,
                    noImage: true,
                    x,
                    y
                }
            }).then((response) => {
                this.list = response.data.response.item_list;
                if (!this.list.length) this.$router.push({ name: 'NotFound' });
                this.this_category = response.data.response.this_category;
            }).finally(() => {
                this.preLoad = 'finish';
            })
        },
        geoLocation() {
            this.$getLocation().then(coordinates => {
                if (coordinates.lat != this.geo.x || coordinates.lng != this.geo.y) {
                    this.geo.x = coordinates.lat;
                    this.geo.y = coordinates.lng;
                    this.api(this.geo.x, this.geo.y);
                }
                this.geo.isActive = true;
                localStorage.setItem('x', coordinates.lat);
                localStorage.setItem('y', coordinates.lng);
            }).catch(error => {
                console.log(error);
                localStorage.setItem('x', 0);
                localStorage.setItem('y', 0);
            });
        },
        sortListByName() {
            this.list = this.list.sort((a, b) => {
                if (a.name.trim() < b.name.trim()) {
                    return -1;
                }
                if (a.name.trim() > b.name.trim()) {
                    return 1;
                }
                return 0;
            })
        },
        getListByGeo() {
            this.list = this.list.sort((a, b) => (
                !a.addresses[0].distance_integer ? 1 :
                    (!b.addresses[0].distance_integer ? -1 :
                        (a.addresses[0].distance_integer - b.addresses[0].distance_integer)
                    )
            ))
        },
    },
    mounted() {
        this.geo.x = localStorage.getItem('x');
        this.geo.y = localStorage.getItem('y');
        this.api(this.geo.x, this.geo.y);
        this.geoLocation();

    },

}
</script>
<style scoped>
.sort_box {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 12px;
    margin-bottom: 20px;
}

.sort_box_title {
    font-weight: bold;
    padding: 10px 15px;
}

.sort_box button {
    padding: 10px 15px;
    background: #fff;
    border-left: 1px solid rgba(0, 0, 0, .1);
    transition: .2s;
}

.sort_box button:hover {
    cursor: pointer;
    transition: .2s;
    background: rgba(var(--main-color), .1);
}

.sort_box button:last-child {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}
</style>