<template>
    <div v-if="item" class="org_card">
        <router-link :to="{name:'OrganisationGet' ,params: { id:item.id }}">
            <b-row no-gutters>
                <b-col md="5" class="align-center">
                    <img :src="item.image ? item.image : '/images/def.jpg'" alt="org_logo" class="img-fluid">
                </b-col>
                <b-col md="7" class="org_card_body">
                    <h3 v-html="item.name" class="org_card_name"></h3>
                    <div>
                        <div v-if="item.addresses && item.addresses.length && item.addresses[0].address" class="org_card_desc">
                            <span class="org_card_desc_title">Адрес</span>
                            <div v-html="item.addresses[0].address" class="org_card_desc_desc"></div>
                        </div>
                        <div v-if="item.addresses && item.addresses.length && item.addresses[0].working_days" class="org_card_desc">
                            <span class="org_card_desc_title">График работы</span>
                            <div v-html="item.addresses[0].working_days" class="org_card_desc_desc"></div>
                        </div>
                        <div v-if="item.telephones && item.telephones.length && item.telephones[0].number" class="org_card_desc">
                            <span class="org_card_desc_title">Телефон</span>
                            <div v-html="item.telephones[0].number" class="org_card_desc_desc"></div>
                        </div>
                        <div v-if="item.comments_count" class="comments_count_box">
                            <div v-if="item.comments_count === 1" v-html="item.comments_count + ' отзыв'"></div>
                            <div v-else-if="item.comments_count >= 2 && item.comments_count <= 4" v-html="item.comments_count + ' отзыва'"></div>
                            <div v-else v-html="item.comments_count + ' отзывов'"></div>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </router-link>
    </div>
</template>
<script>
export default {
    name: "Etalon",
    props: {
        item: Object
    },
    components: {},
    data() {
        return {}
    },

}
</script>
<style scoped>
@media only screen and (max-width: 450px) {
    .org_card_name {
        font-size: 18px !important;
    }

    .org_card_desc {
        font-size: 14px !important;
    }
}

@media (min-width: 768px) {
    .org_card:hover {
        background: rgba(var(--main-color), .05);
        transition: var(--main-transition);
        cursor: pointer;
    }

    .org_card:hover .org_card_desc_title {
        background: transparent;
        transition: var(--main-transition);
    }

    .org_card:hover .org_card_desc_desc {
        background: transparent;
        transition: var(--main-transition);
    }
}

img {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}

.org_card {
    background: #fff;
    border-radius: 12px;
    margin-bottom: 15px;
}

.org_card_body {
    padding: 15px !important;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: space-between;
}

.org_card_name {
    color: #007de3;
    display: block;
    font-weight: 600;
    font-size: 21px;
    line-height: 28px;
    padding-bottom: 15px;
}

.org_card_desc {
    position: relative;
    display: flex;
    justify-content: space-between;
    z-index: 1;
    margin-bottom: 10px;
    color: #2c3e50;
}

.org_card_desc:before {
    background-image: linear-gradient(to right, #e3e7ec 25%, transparent, 50%, transparent 100%);
    background-repeat: repeat-x;
    background-size: 3px 2px;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 7px;
    height: 2px;
    z-index: -1;
}

.org_card_desc_title {
    padding-right: 4px;
    background-color: #fff;
    font-weight: 600;
}

.org_card_desc_desc {
    background-color: #fff;
    padding-left: 4px;
    text-align: right;
}

.comments_count_box {
    text-align: right;
    font-size: 14px;
    line-height: 24px;
    color: #8491a5;
}

.align-center {
    display: flex;
    align-items: center;
}
</style>